import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "We challenge",
            title: 'to secure and optimize quality competitiveness through Infinite Precision Technology',
            paragraphText: 'We are an irreplaceable leader in the localization of automobile parts by attaining global talent and strategic alliances with global corporations.'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: '',
            paragraphText: 'Electronic Parking Brake Actuator [Cable Puller Type]',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: '',
            paragraphText: 'Φ75 Disk Electronic Horn (12V)',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: '',
            paragraphText: 'CELL CASE (Hybrid Vehicle)',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: '',
            paragraphText: 'Park Brake Cable.',
            number: '4'
        },
       
       
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}