import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-about',
    templateUrl: './aboutpage-about.component.html',
    styleUrls: ['./aboutpage-about.component.scss']
})
export class AboutpageAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/Aboutus1.jpeg'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'About Us',
            title: 'Welcome to INFAC India',
            paragraphText1: 'INFAC India was established in Chennai, India in August 1998 to develop automotive emerging markets. We produce Control Cable, Battery Cable, Ignition Cable and Feeder Cable to Hyundai Motor India, Hyundai Mobis, Turkey, HAOS, GMI, Volkswagen and else. INFAC India has expanded its plant in January 2012 to provide a production base for stable product supply.',
            paragraphText2: 'Founded on 5th Aug 1998 in the name of M/s Sam Yeong Cables Name changed as INFAC INDIA during 2004',
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: 'More than 25 Years',
            subTitle: 'On the market'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '500+',
            subTitle: 'Employees'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Satisfaction rate'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '7',
            subTitle: 'Countries'
        }
    ]
    aboutText: Text[] = [
        {
            title: 'Our History',
            paragraphText: 'Real innovations and a positive customer experience are the heart of successful communication.',
            featuresList1: 'Activate Listening',
            featuresList2: 'Brilliant minds',
            featuresList3: 'Better. Best. Wow!',
            featuresList4: 'Branding it better!',
            icon: 'flaticon-tick'
        },
        {
            title: 'Our Mission',
            paragraphText: 'Real innovations and a positive customer experience are the heart of successful communication.',
            featuresList1: 'Creating. Results.',
            featuresList2: 'Expect more',
            featuresList3: 'Good thinking',
            featuresList4: 'In real we trust',
            icon: 'flaticon-tick'
        },
        {
            title: 'Who we are',
            paragraphText: 'Real innovations and a positive customer experience are the heart of successful communication.',
            featuresList1: 'Stay real. Always.',
            featuresList2: 'We have you covered',
            featuresList3: 'We turn heads',
            featuresList4: 'Your brand, promoted',
            icon: 'flaticon-tick'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}
class Text {
    title : string;
    paragraphText : string;
    featuresList1 : string;
    featuresList2 : string;
    featuresList3 : string;
    featuresList4 : string;
    icon : string;
}