<section class="gallery-area  pb-70">
    <div style="width:100% ;text-align: center;"><h3>Quality, Environment, Safety/Health, Business Continuity Management System</h3> </div> 
     <div class="container">
         <div class="row">
             <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of galleryImages;" >
                 <div class="single-gallery-item">
                     <a data-fancybox="gallery" href="{{ Image.img }}">
                         <img [src]="Image.img" alt="image" style="width: 100%;
                         height: 550px;">
                        
                     </a>
                 </div>
                 <div style="
                 width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 0px 15px 0px;
    color: rgb(255, 255, 255);
    font-size: 17px;
    background-color: rgb(239, 58, 67);
                 ;">{{Image.data}}</div>
             </div>
         </div>
     </div>
 </section>