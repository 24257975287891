import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-case-studies-three-columns-page',
    templateUrl: './case-studies-three-columns-page.component.html',
    styleUrls: ['./case-studies-three-columns-page.component.scss']
})
export class CaseStudiesThreeColumnsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Products'
        }
    ]
    singleProjectsBox: singleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/1.png',
            title: 'Control_Cables',
            subTitle: 'Hood Latch Release Cable',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/2.png',
            title: 'Control_Cables',
            subTitle: 'Automatic Transmission Shift Cable',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/13.png',
            title: '',
            subTitle: 'Mannual Transmission Shift Cable',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/6.png',
            title: '',
            subTitle: 'Fuel Filler Cable',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/4.png',
            title: 'Data Scientist',
            subTitle: 'Accelerator Cable',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/5.png',
            title: 'Benefits Research',
            subTitle: 'Manual Parking Release Cable',
            link: 'case-studies-details'
        }
        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/7.png',
            title: 'Benefits Research',
            subTitle: 'Shift Lock Cable',
            link: 'case-studies-details'
        }

        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/3.png',
            title: 'Benefits Research',
            subTitle: 'Parking Brake Cable',
            link: 'case-studies-details'
        }

        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/11.png',
            title: 'Benefits Research',
            subTitle: 'Wiring Harness',
            link: 'case-studies-details'
        }
        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/12.png',
            title: 'Benefits Research',
            subTitle: 'Spark Plug Cable',
            link: 'case-studies-details'
        }
        
        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/14.jpg',
            title: 'Benefits Research',
            subTitle: 'Sharkfin Antenna',
            link: 'case-studies-details'
        }

        ,
        {
            icon: 'bx bx-plus',
            img: 'assets/img/products/15.jpg',
            title: 'Benefits Research',
            subTitle: 'Horn',
            link: 'case-studies-details'
        }
    ]

}
class pageTitle {
    title : string;
}
class singleProjectsContent {
    icon : string;
    img : string;
    title : string;
    subTitle : string;
    link : string;
}