import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-policy',
  templateUrl: './e-policy.component.html',
  styleUrls: ['./e-policy.component.scss']
})
export class EPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
