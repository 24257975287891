import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Gallery'
        }
    ]
    galleryImages: Img[] = [
        {
            img: 'assets/img/Quality/Picture1.png',
            data: 'Climatic chamber'
        }, {
            img: 'assets/img/Quality/Picture2.png',
            data: 'VW Durability tester'
        },
        {
            img: 'assets/img/Quality/Picture3.png',
            data: 'Sliding Friction Tester'
        },
        {
            img: 'assets/img/Quality/Picture4.png',
            data: 'Tensile Tester'
        }, {
            img: 'assets/img/Quality/Picture5.png',
            data: 'MTM Durability tester'
        },
        {
            img: 'assets/img/Quality/Picture6.png',
            data: 'Efficiency Tester'
        },
        {
            img: 'assets/img/Quality/Picture7.png',
            data: 'Horn Durability Tester'
        }, {
            img: 'assets/img/Quality/Picture8.png',
            data: 'Perfomance Tester with Test Bed'
        },
        {
            img: 'assets/img/Quality/Picture9.png',
            data: 'Leak Tightness test'
        },

    ]

}
class pageTitle {
    title : string;
}
class Image {
    img : string;
}

class Img {
    img : string;
    data: string
}