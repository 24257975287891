import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor( public appservice: AppService, private toastr: ToastrService, private http: HttpClient,  private router: Router, private route: ActivatedRoute) 
    {
    }
    public add: any = {};
    public addValidation: boolean = false;
    public btndisable:boolean=false;
    public Error='';
    headers;
    data;
    isadd = "0";
     file:File;
    addData(f) {
        if( f.form.value.Emp_Name=='')
        {
          this.Error="Enter Name";
          this.addValidation = true;
        return;
        }
        
        if( f.form.value.Phone_No=='')
        {
          this.Error="Enter Name";
          this.addValidation = true;
        return;
        }
        
                f.form.value.ID = "0";
                f.form.value.Table_Name = "Employee_Profile";
            
                f.form.value.Company = this.appservice.Company;
                f.form.value.EMP_ID = this.add.EMP_ID;
            
                f.form.value.File = this.file;
            
                if (f.invalid === true)
                {
                  this.addValidation = true;
              }
              
              
                else {
                  this.addValidation = false;
                
                  this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
            
                  this.btndisable=true;     
                   this.http.post(this.appservice.Server_URL + 'api/Transaction/Post_Enqiry_Data', f.form.value, { headers: this.headers })
                  .subscribe(
                   
                    (val: string) => {
                       
                        this.btndisable=false;
            
                        if (val == "True") {
                        alert(" Submited Successfully")
                         
                        }
                        else {
                          this.toastr.error(val, "Error", { timeOut: 3000 });
                        }
                      },
                      response => {
                        this.toastr.error('Error ', response, {
                          timeOut: 3000
                        });
                      });
                }
              }
    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            location: 'No.113, Ellaiamman Koil Street, Padappai, Kundrathur Tk, Kanchipuram Dist-601 301.'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '+91-95512 59206',
            email: 'hrd@infacindia.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            text1: 'Mon- Sat : 08:30AM - 5:00PM',
            text2: ''
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact.png'
        }
    ]

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}