<section class="about-area pb-100" style="padding-right: 10%; padding-left: 10%;  ">
    <div class="container-fluid">
        <div class="row align-items-center">
           

            <div class="col-lg-6 col-md-12">
                <div class="about-content" >
                    <div class="content" *ngFor="let Content of aboutContent;">
                        <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{Content.subTitle}}</span>
                        <h2>{{Content.title}}</h2>
                        <p>{{Content.paragraphText1}}</p>
                        <ul class="features-list">
                            <li *ngFor="let List of featuresList;"><span>
                                <div class="icon">
                                    <img [src]="List.icon" alt="image">
                                </div>
                                <h3>{{List.title}}</h3>
                                <p>{{List.subTitle}}</p>
                            </span></li>
                        </ul>
                        <p>{{Content.paragraphText2}}</p>
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image" *ngFor="let Image of aboutImage;" style="border: 5px solid #cbcbcb;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>

        </div>
    </div>

    <div class="circle-shape1"><img src="assets/img/shape/circle-shape1.png" alt="image"></div>
</section>