<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" >
            <h2>E-waste Management</h2>
            <ul>
                
                <div class="faq-style1-content">
                    <p>Electronic waste, or e-waste, is a term for electronic products that have become unwanted, non-working or obsolete, and have essentially reached the end of their useful life.</p>
                    <p>As per E-waste Rule 2016, the E waste defined as ‘electrical and electronic equipment, whole or in part discarded as waste by the consumer or bulk consumer as well as rejects from manufacturing, refurbishment and repair processes.</p>
                    <p>E-waste contains many valuable, recoverable materials such as aluminum, copper, gold, silver, plastics, and ferrous metals. In order to conserve natural resources and the energy needed to produce new electronic equipment from virgin resources, electronic equipment can be refurbished, reused, and recycled instead of being land-filled.</p>
                    <p>E-waste also contains toxic and hazardous materials including mercury, lead, cadmium, beryllium, chromium, and chemical flame retardants, which have the potential to leach into our soil and water.</p>
                    <h4>Benefits of E-waste Recycling</h4>
                   
                    <p> <strong>Electronics Recycling Conserves Natural Resources</strong> There are many materials that can be recovered from old electronics. These materials can be used to make new products, thus reducing the need to mine for new raw materials. For instance, various metals can be recovered from computer circuit boards and other electronics, and the plastics and glass found in computer monitors and televisions can be recycled.</p>
                        <p> <strong>Electronics Recycling Supports the Community</strong> Donating your old electronics plays an important role in the provision of refurbished products such as computers and mobile phones, which can be of great help to low-income families, schools, and not-for-profit organizations. It also helps individuals gain access to technology that they could not have otherwise afforded.</p>
                            <p><strong>Electronics Recycling Creates Employment Locally</strong> Considering that around 90 percent of electronic equipment is recyclable, electronics recycling can play a significant role in creating employment. This is because new firms dealing with electronics recycling will form and existing firms will look to employ more people to recover recyclable materials. This can be triggered by the increase in the demand for electronics recycling.</p>
                                <p><strong>Electronics Recycling Helps Protect Public Health and the Environment</strong> Many electronics have toxic or hazardous materials such as mercury and lead, which can be harmful to the environment if disposed of in trashcans. Reusing and recycling electronics safely helps in keeping the hazardous materials from harming humans or the environment. For example, televisions and computer monitors are hazardous since they have lead in them. Printed circuit boards contain harmful materials such as cadmium, lead, mercury and chromium. Also, batteries in computers and other electronics may contain hazardous materials such as cadmium, mercury and lead.Instead of keeping old electronics in the house or dumping them in landfills, recycling or reusing them is an appropriate option that should be supported by individuals and organizations. Considering the benefits of electronics recycling, it is very important that people in various parts around the world embrace this concept.</p>
                                    <p><strong>Creates Jobs: </strong>E-waste recycling creates new jobs for professional recyclers and creates a second market for the recycled materials.</p>
                   
                    <h5>Collection Mechanism of e-waste:-</h5>
                    <p><strong>The company had planned to adopted the Buy-back Scheme</strong></p>
                    <p>Under Buy back Scheme, in cases where the customer wants to discard their old product, they may call at the Toll Free number (1800-102-5679) and our representatives shall approach to the customer and buy the said discarded product from the customer and in consideration.</p>
                    <p>The Customer shall also be free to deposit the discarded product at the nearest collection point and he will get the discount voucher and shall be eligible for the discount on purchase of selected new product of the Company. The company will coordinate with other independent vendors who may sale the e-waste/EOL product collected by them.</p>
                    <p>The company will furthermore elaborate its scheme and shall keep updating the same based on the experience faced while implementing the said schemes on a quarterly basis.</p>
                    <h5>Direct Collection from Customer’s premises</h5>
                    <p>For collection of e-waste The Company has setup toll free number where any customer may call and register itself for either depositing its old product as free of cost or otherwise under buy-back programme initiated by company. The company shall give its toll free number on their product manuals, website, social networking platforms and at its various connected premises for its customer information for disposal of their of e-waste. The customer can directly call on these numbers and the e-waste will be directly collected by the company or by its recycler partner. The company has recycler partner who has manage logistic service through Professional Logistics  Private Limited. available at most of the locations at its collection centers.</p>
                    <h5>Recycling of E-waste:</h5>
                    <p>The company has tied -up with the E-Waste Recyclers India (EWRI)  for collection of all our E-waste on PAN India and disposes the same at their plant at   Shed No.15, Roz ka Meo Industrial Area, Nuh, Haryana and India. Customer can reach or call on Toll Free No 1800-102-5679. Our representatives explain them about process of disposal and make them aware about nearest drop point available to drop the e-waste also give information about incentive we will offer to them against their end of life product. If any customer wants to handover the material from their door steps, we do send either our logistics team or E-waste Solutions team to collect the items and channelize the same to our e-waste partner plant for final processing.</p>
                    <h6>Do’s&amp; Don’ts</h6>
                    <p><strong> Do’s: </strong></p>
                
                        <p>Always look for information on the catalogue with your product for end-of-life equipment handling.</p>
                        <P>Ensure that only Authorized Recyclers/Dismantler handle your electronic (i.e. LED Tv’s and accessories) products
                        <p>Always call at our toll-free No’s to Dispose products that have reached end-of life</p>
                        <p>Always drop your used electronic products, batteries or any accessories when they reach the end of their life at your nearest Authorized E-Waste Collection Points.</p>
                        <p>Always disconnect the battery from product, and ensure any glass surface is protected against breakage.</p>
                
                    <p><strong> Don’ts: </strong></p>
                    
                        <p>Do not dismantle your electronic Products on your own</p>
                            <p>Do not throw electronics in bins having “Do not Dispose” sign.</p>
                                <p>Do not give e-waste to informal and unorganized sectors like Local Scrap Dealer/ Rag Pickers.</p>
                    
                    <p>Do not dispose your product in garbage bins along with municipal waste that ultimately reaches landfills.</p>
                    <p>M/s E-waste Recycler India has signed an agreement with Professional Logistics Private Limited for reverse logistics for Channelization of E-waste to the facility.</p>
                	<p>Open New Collection Center Professional Logistics PDF file <a href="http://www.modernarrow.co.in/assets/inc/collection-center-professional-logistic.pdf">Click Here</a>.</p>
                  </div>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

