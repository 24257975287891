


<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" style="margin-top: 100px; ">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/img/slider/slider-4-1.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5   data-wow-delay="00ms" data-wow-duration="1000ms" class="wow animate__ animate__fadeInLeft     animated"
          style="visibility: visible; animation-duration: 1000ms; animation-delay: 0ms; animation-name: fadeInLeft;">Infinite Accuracy</h5>
          <p> </p>
        </div>
      
      </div>
      <div class="carousel-item">
        <img src="assets/img/slider/slider-4-2.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          
          <h5>Achieve ZERO PPM</h5>
          <p> </p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="assets/img/slider/slider-4-3.jpg" class="d-block w-100" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>Devote to customers with quality and sincerity</h5>
          <p> </p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

<div style="width: 100%; background-color:#f0353e; color :white; margin:5px;font-weight: 700; "  >
 
  <marquee  onmouseover="this.stop();" onmouseout="this.start();" scrollamount="10"> <span  style="color: #f6f697e0;
    font-family: sans-serif;" >CURRENT OPENING : </span>
    <span (click)="load_career()"  *ngFor="let data of rows;" style="cursor:pointer">Job Title : {{data.Job_Name}},  <span style="color: rgb(248, 237, 237);"> </span>  <a style="color: rgb(26, 5, 93);">Apply Here</a>  </span> </marquee>
</div>
<app-homeone-about></app-homeone-about>

<app-homeone-services></app-homeone-services>

<section class="process-area pt-100 pb-70">
    <app-homeone-process></app-homeone-process>
</section>



<app-homeone-partner></app-homeone-partner>

