
<div class="top-main text-md-right m-none" style="z-index: 999999;    top: 0px; position: fixed; width: 100%; height: 42px;  ">
    <div class="">
    <div class="row">
    <div style="width: 33%" class="col-md-3 xs-mb-10 bg-white-theme">
    </div>
    <div class="col-lg-8 col-md-8 bg-links">
    <div class="topbar-social" style="line-height: 42px; text-align: right;">
   <span style="border-left: 1px solid rgba(255, 255, 255, 0.3); color: white; padding: 10px;">HAVE QUESTION? CONTACT US</span>
   <span style="border-left: 1px solid rgba(255, 255, 255, 0.3); color: white; padding: 10px; padding-right: 30px;"> <span style="font-size: 15px;padding-right: 5px;" class="bx bx-phone-call"></span>+91-9380645429</span>
    </div>
    </div>
    </div>
    </div>
    </div>

<div class="navbar-area is-sticky" style="padding-top: 40px;">
    
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/infac_logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/infac_logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                       
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">HOME</a></li>

                        <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ABOUT US</a></li>


                        <li class="nav-item"><a routerLink="/case-studies-3-columns" class="nav-link" > PRODUCTS</a></li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" > QUALITY</a></li>

                        <li class="nav-item"><a routerLink="/e-waste" class="nav-link" > E-WASTE</a></li>
                        <li class="nav-item"><a routerLink="/e-policy" class="nav-link" > ENVIRONMENTAL POLICY</a></li>

                     
                        <li class="nav-item"><a routerLink="/blog-details" class="nav-link" >CAREERS</a></li>


                        <li class="nav-item"><a routerLink="/contact" class="nav-link" >CONTACT US</a></li>


                    </ul>

                   
                </div>
            </nav>
        </div>
    </div>

  
</div>