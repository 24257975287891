import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
    selector: 'app-blog-details-page',
    templateUrl: './blog-details-page.component.html',
    styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {
rows=[];

constructor( public appservice: AppService, private toastr: ToastrService, private http: HttpClient,  private router: Router, private route: ActivatedRoute) {


  this.server=this.appservice.Server_URL+"Api/Master/UploadCalibration_Document?ID="+this.add.ID+"&Company="+this.appservice.Company+"&Name="+this.add.Calibration_No;
  

        this.appservice.get("Api/Master/get_Job?From=20-Jan-2002&To=20-Jan-2023&").subscribe((res: any) => {
            this.rows = JSON.parse(res).record;
           
          });
          this.add.Phone_No="";
          this.add.Emp_Name="";
          this.add.Email_ID="";

    }

    public add: any = {};
    public addValidation: boolean = false;
    public btndisable:boolean=false;
  
    headers;
    data;
    isadd = "0";
     file:File;
    fileChange(event) {
        let fileList: FileList = event.target.files;
        if(fileList.length > 0) {
             this.file = fileList[0];
            
        }
    }

  uploadedFiles: any[] = [];
  file_Name="";
 certificate_Name="";
  server;
  onUpload(event) {
      for(let file of event.files) {
          this.uploadedFiles.push(file);
        
        this.toastr.success("File Uploaded  Success", 'Msg');
      }
  }
 
  isupload:boolean=true
    name_change()
    {

      this.server=this.appservice.Server_URL+"Api/Transaction/Post_Resume?Phone_No="+this.add.Phone_No+"&Company="+this.appservice.Company;

      
      if(this.add.Phone_No.length<=9)
      {
      this.isupload=true;
      }
      else
      {
        this.isupload=false;
      }

    }
public Error='';
    addData(f) {
if( f.form.value.Emp_Name=='')
{
  this.Error="Enter Name";
  this.addValidation = true;
return;
}

if( f.form.value.Phone_No=='')
{
  this.Error="Enter Name";
  this.addValidation = true;
return;
}

        f.form.value.ID = "0";
        f.form.value.Table_Name = "Employee_Profile";
    
        f.form.value.Company = this.appservice.Company;
        f.form.value.EMP_ID = this.add.EMP_ID;
    
        f.form.value.File = this.file;
    
        if (f.invalid === true)
        {
          this.addValidation = true;
      }
      
      
        else {
          this.addValidation = false;
        
          this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    
          this.btndisable=true;     
           this.http.post(this.appservice.Server_URL + 'api/Transaction/Post_User_Data', f.form.value, { headers: this.headers })
          .subscribe(
           
            (val: string) => {
               
                this.btndisable=false;
    
                if (val == "True") {
                alert("Resume Submited Successfully")
                 
                }
                else {
                  this.toastr.error(val, "Error", { timeOut: 3000 });
                }
              },
              response => {
                this.toastr.error('Error ', response, {
                  timeOut: 3000
                });
              });
        }
      }


    ngOnInit(): void {
    }
    pageTitleArea: pageTitle[] = [
        {
            title: 'Level Up your career with INFAC',
            subTitle: 'Our global enterprise feels ​​more like a family. We\'re just passionate about our ambitious goals and the journey that\'s going to take us there. Grow your career with us and make a difference in one of the worlds fastest-growing industries.'
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}