import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-partner',
    templateUrl: './homeone-partner.component.html',
    styleUrls: ['./homeone-partner.component.scss']
})
export class HomeonePartnerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    partnerItem: Item[] = [
        {
            img: "assets/img/partner/C1.png"
        },
        {
            img: "assets/img/partner/C2.png"
        },
        {
            img: "assets/img/partner/C3.png"
        },
        {
            img: "assets/img/partner/C4.png"
        },
        {
            img: "assets/img/partner/C5.png"
        }
        ,
        {
            img: "assets/img/partner/C7.png"
        }
        ,
        {
            img: "assets/img/partner/C8.jpg"
        },
    ]

}
class Item {
    img : string;
}