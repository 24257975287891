<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" >
            <h2>ENVIRONMENTAL POLICY</h2>
            <ul>
                
                <div class="faq-style1-content">
                    <p>We at INFAC India committed to both the protection of the environment and evaluating the impact that the organization’s services can have.</p>

                    <p>Compliance with legislation is the minimum standard to which the company shall adhere. The company shall also endeavor to follow best practice with due regard for its business needs. In line with the requirements of ISO 14001:2015 and the expectations of all interested parties. The objectives for the company under this policy are to:</p>

                  
                   <div style=" padding-left: 100px;">
                    <p> <strong>•</strong> 	Reduce the carbon footprint in the business</p>
                    <p> <strong>•</strong>  Reduce energy consumption</p>
                    <p> <strong>•</strong> 	Utilization of renewable energy</p>
                    <p> <strong>•</strong> 	Greenhouse gas reduction and management</p>
                    <p> <strong>•</strong> 	Non-usage of Ozone layer depletion gas </p>
                    <p> <strong>•</strong> 	Chemical substance management </p>
                    <p> <strong>•</strong> 	Waste management (Reduce, reuse, and recycling of all wastes) </p>
                    <p> <strong>•</strong> 	Sustainability resource management </p>
                    <p> <strong>•</strong> 	Water usage and management </p>
                    <p> <strong>•</strong> 	Soil pollution management  </p>
                    <p> <strong>•</strong> 	Air pollution management </p>
                    <p> <strong>•</strong> 	Controlling of noise emission </p>
                </div>
                   
                    <p>All Directors are responsible for communicating and implementing this policy and will ensure that:</p>

                    <div style=" padding-left: 100px;">
                    <p> <strong>•</strong> 	Training is provided as part of staff development taking into account responsibilities.</p>
                    <p> <strong>•</strong> 	Favor the use of suppliers who are committed to environmental good practice.</p>
                    <p> <strong>•</strong> 	Carbon offsetting is implemented, that as a minimum, is commensurate to the level of the business travel.</p>
                    <p> <strong>•</strong> 	Efforts are made to minimize travel by maximizing IT systems.</p>
                    <p> <strong>•</strong> 	Pollution is prevented in all forms, especially from redundant electrical equipment.</p>
                    <p> <strong>•</strong> 	All incidents detrimental to the environment are reported, investigated and action taken to prevent reoccurrence.</p>
                </div>
                  </div>


                  
            </ul>

            
            <div class="faq-style1-content" style="margin-top: 30px;">
                <table style="width: 100%; text-align: center;">
                <Tr>
                    <td>
                        <img style="width: 100px;" src="assets/img/MD.png" alt="image"><br>
                        Mr.KWON SOON SUK	<br>						
MANAGING DIRECTOR

                    </td>
                    <td>
<br>
<br>
                        Date: 01.04.2024<br>
                        Revision: 04


                    </td>
                </Tr>
            </table>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

