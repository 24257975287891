import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Gallery'
      }
  ]
  galleryImages: Img[] = [
      {
          img: 'assets/img/certificate1.png',
          data: 'IATF 16949:2016'
      }, {
          img: 'assets/img/certificate2.png',
          data: 'ISO 14001:2015-EMS'
      },
      {
          img: 'assets/img/certificate3.png',
          data: 'ISO 45001:2018-OHSAS'
      }
      

  ]

}
class pageTitle {
  title : string;
}
class Image {
  img : string;
}

class Img {
  img : string;
  data: string
}