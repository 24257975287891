<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                
                <li>{{pageTitle.subTitle}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="blog-details-area ">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="blog-details-desc">
                   

                   

                    <div class="comments-area">
                        <h3 class="comments-title">Current Opening</h3>

                
                        <ol class="comment-list">
                            <li class="comment" *ngFor="let data of rows;">
                                <article class="comment-body" >
                                    <footer class="comment-meta"  >
                                        <div class="comment-author vcard">
                                           
                                            <b class="fn">{{data.Job_Name}}</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                <span >Description : </span><span style="color: rgb(2, 2, 2) ;"> {{data.Description_}}</span>
                                            </a>
                                        </div>
                                        <div class="comment-metadata">
                                            <a href="#">
                                                <span >Qualification : </span><span style="color: rgb(2, 2, 2) ;">  {{data.Qualification}}</span>
                                            </a>
                                        </div>
                                        <div class="comment-metadata">
                                            <a href="#">
                                                <span>Posted :  {{data.Created_Date}}</span>
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{data.Salary}}   </p>
                                    </div>

                                   
                                </article>

                            
                            </li>

                          
                        </ol>

                     
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="blog-details-desc">
                   

                   

                    <div class="comments-area">
                      
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Submit your Resume</h3>

                            <form class="comment-form"  #addForm="ngForm" id="fm" (ngSubmit)="addData(addForm)">
                                <p class="comment-notes">
                                   
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*"required name="author" required [(ngModel)]="add.Emp_Name" name="Emp_Name" #Emp_Name="ngModel">
                                    <label>{{Error}}</label>
                                </p>
                                <div *ngIf="addForm.submitted && Emp_Name.invalid" class="invalid-feedback ">
                                    <div class="error"> Phone No Should not entry</div>
                                </div>

                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" required [(ngModel)]="add.Email_ID" name="Email_ID" #Email_ID="ngModel"  placeholder="Your Email*"  required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Contacs No</label>
                                    <input type="text" id="Contact_No" (keyup)="name_change()" placeholder="Contact No" required [(ngModel)]="add.Phone_No" name="Phone_No" #Phone_No="ngModel">
                                  
                                </p>
                              
                               
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="3" maxlength="65525" 
                                    [(ngModel)]="add.Description_" name="Description_" #Description_="ngModel"
                                    ></textarea>
                                </p>

                             
                    
                                    
                               <div *ngIf="addValidation==true && add.Emp_Name==''" style="color :red"> Customer Name Should not entry</div>
                               <div *ngIf="addValidation==true && add.Phone_No==''"  class="error" style="color :red"> Phone No Should not entry</div>
                               <div *ngIf="addValidation==true && add.Phone_No.length!=10" class="error" style="color :red"> Phone No Should be 10 Digists</div>
                            
                               <div *ngIf="addValidation==true && add.Email_ID==''" class="error" style="color :red"> Email ID Should not entry</div>
                            

                               <p class="comment-form-comment form-submit" style="display:inline-flex ;text-align: center; ">

                                <p-fileUpload [disabled]="this.isupload" (click)="name_change()" chooseLabel=" Upload Resume"
                                mode="basic" auto=true name="myfile[]" url="{{server}}"
                                (onUpload)="onUpload($event)"maxFileSize="90000000"></p-fileUpload>
                           
                                &nbsp;&nbsp;&nbsp;
                                    <input type="submit"  name="submit" id="submit"  style="border-radius: 10px;" class="submit" value="Send Resume">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>