import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-about',
    templateUrl: './homeone-about.component.html',
    styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/home1.jpg'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'About Us',
            title: 'Welcome to INFAC',
            paragraphText1: 'INFAC India was established in Chennai, India in August 1998 to develop automotive emerging markets. We produce Control Cable, Battery Cable, Ignition Cable and Feeder Cable to Hyundai Motor India, Hyundai Mobis, Turkey, HAOS, GMI, Volkswagen and else. INFAC India has expanded its plant in January 2012 to provide a production base for stable product supply.',
            paragraphText2: 'Founded on 5th Aug 1998 in the name of M/s Sam Yeong Cables Name changed as INFAC INDIA during 2004',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'More About Us',
            defaultBtnLink: 'about-us'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: 'More than 25 Years',
            subTitle: 'On the market'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '500+',
            subTitle: 'Employees'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Satisfaction rate'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '7',
            subTitle: 'Countries'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}