


<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2 style="font-size: 28px;
            font-family: sans-serif; color:#c81f11">INFAC's Quality Goal is</h2>
       
        <h2 style="font-size: 26px;
        font-family: sans-serif; color:#106dbf">"Exceeding the Customer Expectation"</h2>
         <h2 style="font-size: 20px;
         font-family: sans-serif; color:#0c0c0d">with Infinite Accuracy</h2>
            <ul>
                <li> <span style="color: #c81f11;">INFAC</span> , a leading company in the global auto parts industry in the 21st century , has the beginning and end of its core competencies in strict <span style="color: #c81f11;">quality policies</span> .
                    Continuous supply of defect-free products based on the best quality competitiveness is the promise to our customers and the core value of INFAC.</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<app-certificate></app-certificate>

<section class="gallery-area  pb-70">
   <div style="width:100% ;text-align: center;"><h3>Testing Facilities</h3> </div> 
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of galleryImages;" >
                <div class="single-gallery-item" style="    border: 5px solid #f43333;    margin-bottom: 0px;
                border-radius: 0px;">
                    <a data-fancybox="gallery" href="{{ Image.img }}">
                        <img [src]="Image.img" alt="image" style="width: 100%;
                        height: 250px;">
                       
                    </a>
                </div>
                <div style="line-height: 40px; width: 100%;text-align: center;  margin-bottom: 10px; ;">{{Image.data}}</div>
            </div>
        </div>
    </div>
</section>