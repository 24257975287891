import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-history',
    templateUrl: './aboutpage-history.component.html',
    styleUrls: ['./aboutpage-history.component.scss']
})
export class AboutpageHistoryComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our History",
            title: 'History Begins on 05th Aug 1998',
            paragraphText: 'Founded on 05th Aug 1998  in the name of M/s Sam Yeong Cable'
        }
    ]
    historyTimeline: TimelineBlock[] = [
        {
            year: '2004',
            date: 'February 20',
            title: 'Founded',
            paragraphText: 'Name changed as INFAC India Pvt. Ltd during 2004',
            img: 'assets/img/history/img1.jpg'
        },
        {
            year: '2015',
            date: 'January 14',
            title: 'New Business & Factory Expansion',
            paragraphText: 'Implemented MES system & Started HORN supplies to HMI',
            img: 'assets/img/history/img2.jpg'
        },
        {
            year: '2016 & 2017',
            date: 'March 25',
            title: 'Best Supplier Award & New Business',
            paragraphText: 'Received best supplier award from HMI (2016 / 2017), GMI Received LOI for Control cables from MG Motors ( Hector ), Started Horn Mfg',
            img: 'assets/img/history/img2.jpg'
        },
        {
            year: '2018',
            date: 'December 10',
            title: 'New Business from KIA',
            paragraphText: 'Nominated by VW for PB cable ( INDIA 2.0 )LOI from Renault for Radio cable LOI from Lumax Mannoh for GS cable ( M&M ) – 9 Projects Business agreement signed with KIA for all products.',
            img: 'assets/img/history/img3.jpg'
        },
        {
            year: '2019',
            date: 'December 10',
            title: 'New Business from MG Motor',
            paragraphText: 'Nominated by VW for door latch cables ( INDIA 2.0 )Nominated by MG Motors for CCs ( Model K ).',
            img: 'assets/img/history/img5.png'
        },
        {
            year: '2020',
            date: 'December 10',
            title: 'New Business & Expansion',
            paragraphText: 'Micro Pole & Shark Fin Antenna production started Stores expansion with vertical storage facility.',
            img: 'assets/img/history/img3.jpg'
        }
        ,
        {
            year: '202  ',
            date: 'December 10',
            title: 'New Business from OLA',
            paragraphText: 'Nominated by OLA for battery cables, Earth Cables, Motor Wiring cables',
            img: 'assets/img/history/img4.jpg'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TimelineBlock {
    year : string;
    date : string;
    title : string;
    paragraphText : string;
    img : string;
}