import { DatePipe, DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppService {


  public access_tocken = "";

 public Server_URL = "https://api.infacindia.com/";
 // public Server_URL = "http://localhost:5837/";
 //public Server_URL = "http://163.53.205.145:81/";
  public Company="_2";

  public Ledger_ID=0;
  public Ledger_Name=0;
  public Sales_lenght=0;
  public Sales_Details=[];
  public T_Date = "";
  public T_Date1 = "";

  public Customer_Data:any={}

  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {  
  
    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.T_Date1 = this.datePipe.transform(new Date(), "dd-MMM-yyyy");
  }
  isload:boolean= false;
  headers;
  public tocken = "";
  public S_From ="";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }

  get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }


  public rows=[];
  get_sales_Data() {
this.isload = true;
this.get_Sales_Dues();
this.get("Api/Reports/get_Sales_details?Date=04-Feb-2022").subscribe((res: any) => {
  this.Sales_Details = JSON.parse(res).record;
  
  this.Sales_lenght=this.Sales_Details.length;
  
  this.isload = false;
});

}
public Quotation_Details=[];
public Quotation_lenght=0;

get_Quotation_Data() {
this.isload = true;
this.get_QuoteValue();
this.get("Api/Reports/get_Quotation_details?Date=04-Feb-2022").subscribe((res: any) => {
this.Quotation_Details = JSON.parse(res).record;

this.Quotation_lenght=this.Quotation_Details.length;

this.isload = false;
});

}
Party_Ledger_Row=[];
Party_Ledger_Length=0;

Party_Ledger(data)
{
  this.isload = true;
  this.get("Api/Reports/Party_Ledger?Ledger_ID="+data).subscribe((res: any) => {
  this.Party_Ledger_Row = JSON.parse(res).record;
  this.Party_Ledger_Length=this.Party_Ledger_Row.length;
  this.isload = false;
});

}

DB_Rows=[];
get_db_data()
{
  this.isload = true;
  this.getc("Api/Reports/get_db_data").subscribe((res: any) => {
  this.DB_Rows = JSON.parse(res).record;
  this.isload = false;
});

}

public Stock_rows_P=[];
public Stock_rows=[];
public Stock_rows_length=0;
public Stock_Value=0;
get_Stock_Data() {
this.isload = true;
this.Stock_rows_length=0;
this.Stock_Value=0;
this.getc("Api/Reports/get_Stock_Details").subscribe((res: any) => {
this.Stock_rows = JSON.parse(res).record;
this.Stock_rows_P = JSON.parse(res).record;

    
try
{
  for(var i=0;i<this.Stock_rows.length;i++)
  {
    if(parseFloat(this.Stock_rows[i]["Stock"])>0)
    {
      this.Stock_Value+=(parseFloat(this.Stock_rows[i]["Stock"])*parseFloat(this.Stock_rows[i]["P_R"]));
    }

  }
}catch{}


this.Stock_rows_length=this.Stock_rows.length;
this.isload = false;
});

}
public Bank_Row=[];
get_Bank() {
  this.isload = true;
  this.getc("Api/Reports/get_Bank").subscribe((res: any) => {
  this.Bank_Row = JSON.parse(res).record;
  this.isload = false;
  });
  
  }


public Account_Balance_rows_P=[];
public Account_Balance_rows=[];
public Account_Balance_length=0;
get_Account_Balance() {
this.isload = true;
this.Account_Balance_length=0;
this.getc("Api/Reports/get_Account_Balance").subscribe((res: any) => {
this.Account_Balance_rows = JSON.parse(res).record;
this.Account_Balance_rows_P = JSON.parse(res).record;

this.Account_Balance_length=this.Account_Balance_rows.length;
this.isload = false;
});

}
public cr_Total = 0;
public db_Total = 0;
public From=this.T_Date1;
public To=this.T_Date1;
public Cash_Book_rows_P=[];
public Cash_Book_rows=[];
public Cash_Book_length=0;
public Closing_Balance=0;
public Opening_Amount;
public Opening_Amount_;

public Opening_Credit;
public Opening_Debit;

get_Cash_Book() {
this.isload = true;
this.Cash_Book_length=0;
this.get("Api/Reports/get_Cash_book?From="+this.From+"&To="+this.To).subscribe((res: any) => {
this.Cash_Book_rows = JSON.parse(res).record;
this.Cash_Book_rows_P = JSON.parse(res).record;
this.cr_Total = (this.Cash_Book_rows.reduce((sum, current) => sum + parseFloat(current.Credit), 0)).toFixed(2);
this.db_Total = (this.Cash_Book_rows.reduce((sum, current) => sum + parseFloat(current.Debit), 0)).toFixed(2);
this.Closing_Balance=this.cr_Total-this.db_Total;
this.Opening_Amount_= JSON.parse(res).record[0];
this.Opening_Credit=this.Opening_Amount_.Credit;
this.Opening_Debit=this.Opening_Amount_.Debit;
if(this.Opening_Credit!=0)
{
this.Opening_Amount=this.Opening_Credit;
}else{
  this.Opening_Amount=this.Opening_Debit;

}
this.Cash_Book_length=this.Cash_Book_rows.length;
this.isload = false;
});

}
public Bank="All"
public Business_Book_rows_P=[];
public Business_Book_rows=[];
public Business_Book_length=0;
get_Business_Book() {
this.isload = true;
this.Business_Book_length=0;
this.Opening_Amount_=[];
this.Opening_Credit=[];
this.Opening_Debit=[];
this.get("Api/Reports/get_Business_book?Bank="+this.Bank+"&From="+this.From+"&To="+this.To).subscribe((res: any) => {
this.Business_Book_rows = JSON.parse(res).record;
this.Business_Book_rows_P = JSON.parse(res).record;
this.cr_Total = (this.Business_Book_rows.reduce((sum, current) => sum + parseFloat(current.Credit), 0)).toFixed(2);
this.db_Total = (this.Business_Book_rows.reduce((sum, current) => sum + parseFloat(current.Debit), 0)).toFixed(2);
this.Closing_Balance=this.cr_Total-this.db_Total;
this.Opening_Amount_= JSON.parse(res).record[0];
this.Opening_Credit=this.Opening_Amount_.Credit;
this.Opening_Debit=this.Opening_Amount_.Debit;
if(this.Opening_Credit!=0)
{
this.Opening_Amount=this.Opening_Credit;
}else{
  this.Opening_Amount=this.Opening_Debit;
}
this.Business_Book_length=this.Business_Book_rows.length;
this.isload = false;
});

}
public Item_Master_rows_P=[];

public Item_Master_rows=[];
public Item_Master_rows_length=0;
get_Item_Master() {
this.isload = true;
this.Item_Master_rows_length=0;
this.getc("Api/Master/get_Item_Master_Details").subscribe((res: any) => {
this.Item_Master_rows = JSON.parse(res).record;
this.Item_Master_rows_P = JSON.parse(res).record;

this.Item_Master_rows_length=this.Item_Master_rows.length;
this.isload = false;
});

}

public Home_load_: boolean=false;
public  get_home_data()
{
 this.get_db_data();
  this.get_outstanding_Data();
  this.get_Stock_Data();
  this.Home_load_=true;
}

Out_Data=[];
Out_Data_Lenght=0;

you_pay=0;

you_get=0;
public Out_Datap=[];
get_outstanding_Data() {
  this.isload = true;
  this.you_get=0;
  this.you_pay=0;
  this.getc("Api/Reports/Out_Standing_List").subscribe((res: any) => {
    this.Out_Data = JSON.parse(res).record;
    this.Out_Datap = JSON.parse(res).record;
    
    this.Out_Data_Lenght=this.Out_Data.length;
    
    try
    {
      for(var i=0;i<this.Out_Data.length;i++)
      {
        if(parseFloat(this.Out_Data[i]["Due"])>0)
        {
          this.you_get+=parseFloat(this.Out_Data[i]["Due1"]);
        }
        else
        {
          this.you_pay+=parseFloat(this.Out_Data[i]["Due1"]);
        }

      }
    }catch{}

    this.isload = false;
  });
  
  }
public Ledger_Master_Row=[];
public Ledger_Master_Row_p=[];

Ledger_Master_Row_Length=0;
get_Ledger_Master() {
  this.isload = true;
  this.Ledger_Master_Row=[];
  this.getc("Api/Master/Ledger_Master_List").subscribe((res: any) => {
    this.Ledger_Master_Row = JSON.parse(res).record;
    this.Ledger_Master_Row_p = JSON.parse(res).record;

    this.Ledger_Master_Row_Length=this.Ledger_Master_Row.length;
    
  

    this.isload = false;
  });
  
  }
  public  Q_Row=[];
  public  T_Row=0;
  public  D_Row=[];

get_Quote_Total() {
  

  this.get("Api/Reports/Quote_Total?Month=04-Feb-2022").subscribe((res: any) => {
  this.D_Row = res;


  });
  
  }

public T_Sales=0;
public T_Due=0;

get_Sales_Dues() {
  
  this.T_Sales=0;
this.T_Due=0;
  this.getc("Api/Reports/get_Sales_Due").subscribe((res: any) => {
    try
    {
    this.T_Sales= JSON.parse(res).record[0]["Sales"];
    this.T_Due= JSON.parse(res).record[0]["Due"];
    }catch{}
  });
  
  }




get_sales_search(data) {
  this.Sales_lenght=0;
  this.isload = true;
  this.get("Api/Reports/get_Sales_data?customer="+data).subscribe((res: any) => {
    this.isload = false;

    this.Sales_Details = JSON.parse(res).record;
    
    this.Sales_lenght=this.Sales_Details.length;
    
   
  });
  
  }
  public Call_Status="All";
  public Service_Details_Row=[];
  public Service_lenght=0;
  get_Service_Details() {
    this.Service_lenght=0;
    this.isload = true;
    this.get("Api/Reports/get_Service_Details?From="+this.From+"&To="+this.To+"&Call_Status="+this.Call_Status).subscribe((res: any) => {
      this.isload = false;
  
      this.Service_Details_Row = JSON.parse(res).record;
      
      this.Service_lenght=this.Service_Details_Row.length;
      
     
    });
    
    }
  public Today_Q=0;
public Monthly_Q=0;

get_QuoteValue() {
  
  this.Today_Q=0;
this.Monthly_Q=0;
  this.getc("Api/Reports/get_Quote_Value").subscribe((res: any) => {
    try
    {
    this.Today_Q= JSON.parse(res).record[0]["Today"];
    this.Monthly_Q= JSON.parse(res).record[0]["Month"];
    }catch{}
  });
  
  }

  get_Quotation_search(data) {
    this.Quotation_lenght=0;
    this.isload = true;
    this.get("Api/Reports/get_Quotation_data?customer="+data).subscribe((res: any) => {
      this.isload = false;
  
      this.Quotation_Details = JSON.parse(res).record;
      
      this.Quotation_lenght=this.Quotation_Details.length;
      
     
    });
    
    }
  CREATED_BY=""
  Bill_Format="General";
  Quote_Format="General";
  Msg="";
  Type_="";
  File_Name="";
  Phone_No="";

  open_pdf(data) {

      if(this.Bill_Format=="General"){

      window.open(this.Server_URL+"report/Sales_Bill?Type="+this.Type_+"&Contact="+this.Phone_No+"&Bill_No="+data.Bill_No+"&File_Name="+this.File_Name+"&Company="+this.Company+"&Msg="+this.Msg, "_blank");
 
     }

    }

     open_quote(data) {

      if(this.Quote_Format=="General"){

      window.open(this.Server_URL+"report/Quotation?Type="+this.Type_+"&Contact="+this.Phone_No+"&Bill_No="+data.Bill_No+"&File_Name="+this.File_Name+"&Company="+this.Company+"&Msg="+this.Msg, "_blank");
 
     }
 
     
    
    
  }
  

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }


  public load_page1(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page(data) {
    this.router.navigate([data]);
  }


  public Pending_Orders=[];
  public Pending_Orders_=[];

  public Pending_Orders_Count=0;
  public Pending_Orders_Count2=0;

  get_Enquiry_Data() {
this.isload = true;
this.Pending_Orders=[];

this.get("Api/Master/Pending_Order?Customer="+this.Customer_Data.ID).subscribe((res: any) => {
  this.Pending_Orders = JSON.parse(res).record; 
  this.Pending_Orders_=this.Pending_Orders.filter(e =>e.Accept_Status=="PENDING")
  this.Pending_Orders_Count2=this.Pending_Orders_.length;

  this.Pending_Orders_Count=this.Pending_Orders.length;


  this.isload = false;
});

}

  

 

  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }

}
