import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-homepage-one',
  templateUrl: './homepage-one.component.html',
  styleUrls: ['./homepage-one.component.scss']
})
export class HomepageOneComponent implements OnInit {

  rows=[];
  constructor(public appservice:AppService) {

    
    this.appservice.get("Api/Master/get_Job?From=20-Jan-2002&To=20-Jan-2023&").subscribe((res: any) => {
      this.rows = JSON.parse(res).record;
     
    });
   }

   load_career()
   {
    this.appservice.load_page('blog-details')
   }

  ngOnInit(): void {
  }

}
